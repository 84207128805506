<script lang="ts" setup>
import AppMenuItem from './AppMenuItem.vue'

const {hasPermissions} = useAuth()
const model = ref<any[]>([
  {
    label: 'Inicio',
    items: [
      { label: 'Panel', icon: 'pi pi-fw pi-home', to: '/' }
    ]
  },
  {
    label: 'Ticketera',
    items: [
      { label: 'Eventos', icon: 'pi pi-calendar', to: '/ticketing/events', permissions: 'ticketing.view_event' },
      { label: 'Pedidos', icon: 'pi pi-list', to: '/ticketing/orders', permissions : 'ticketing.view_order' },
      { label: 'Artistas', icon: 'pi pi-users', to: '/ticketing/performers', permissions: 'ticketing.view_performer' },
      { label: 'Usuarios', icon: 'pi pi-user', to: '/users', permissions: 'core.view_user' },
    ]
  },
  {
    label: 'Soporte',
    items: [
      { label: 'Conversaciones', icon: 'pi pi-comments', to: '/support/conversations', permissions: 'support.view_conversation' },
    ]
  },
])

const menuItems = computed<LayoutMenuItem[]>(() => {
  const items: LayoutMenuItem[] = []

  model.value.forEach((item) => {
    const subitems: LayoutMenuItem[] = []

    item.items.forEach((subitem: any) => {
      if (!subitem.permissions) {
        subitems.push(subitem)
      } else {
        // @ts-ignore
        const permissions = Array.isArray(subitem.permissions) ? subitem.permissions : [subitem.permissions]
        if (hasPermissions(permissions)) subitems.push(subitem as LayoutMenuItem)
      }
    })

    if (subitems.length) items.push({label: item.label, items: subitems})
  })

  return items
})
</script>

<template>
  <ul class="layout-menu">
    <template v-for="(item, i) in menuItems" :key="item">
      <app-menu-item v-if="!item.separator" :item="item" :index="i" />
      <li v-if="item.separator" class="menu-separator"></li>
    </template>
  </ul>
</template>
