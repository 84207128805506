import * as Sentry from '@sentry/nuxt'
import type {RouteLocationRaw} from 'vue-router'

export default function useAuth() {
  const account = useState<Account|null>('auth_account', () => null)
  const isAuthenticated = computed<boolean>(() => !!account.value)

  const hasPermission = (perm: string) => {
    if (!account.value) return false
    return account.value.permission_set.includes(perm)
  }

  const hasPermissions = (perms: string[]) => {
    if (!account.value) return false
    return perms.every(perm => hasPermission(perm))
  }

  const logout = async (to?: RouteLocationRaw) => {
    const {$api} = useNuxtApp()
    await $api('/token/delete/', {
      method: 'POST',
    })
      .then(() => {
        account.value = null
        Sentry.setUser(null)
        navigateTo(to ?? { name: 'account-login'})
      })
  }

  const setAccount = async () => {
    const {$api} = useNuxtApp()
    await $api<Account>('/account/')
      .then(response => {
        if (!response) return
        if (!response.is_staff) return
        account.value = response
        Sentry.setUser({email: account.value.email})
      })
  }

  return {
    account,
    hasPermission,
    hasPermissions,
    isAuthenticated,
    logout,
    setAccount,
  }
}
